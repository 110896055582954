/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';

import {
  ShThemePartial,
  ShThemeProvider,
  ShButtonAsync,
  ShLinkText,
  ShInputTextarea,
  ShSpinner,
  ShModal,
  ShButton,
} from '@shoootin/components-web';

import {
  ShLanguageLocales,
  ShLanguageLocale,
  ShLocales,
  ShLocaleConfigs,
  ShLocale,
  ShTranslatableLocale,
} from '@shoootin/config';
import { keyBy, mapValues } from 'lodash';
import {
  ShTranslationApi,
  ShTranslationsDTO,
  ShTranslationKeyDTO,
} from '@shoootin/api';
import { ShColors } from '@shoootin/design-tokens';
import { sortBy } from 'lodash';
import { intersection } from 'lodash';
import { useAsync } from 'react-async-hook';
import { ShTranslationAi } from '../ShTranslationAI/ShTranslationAi';

const TranslationsPageTheme: ShThemePartial = {
  button: { size: 's' },
  input: { size: 's' },
};

const ShLanguageToShLocales: Record<ShLanguageLocale, ShLocale[]> = mapValues(
  keyBy(ShLanguageLocales, (languageLocale) => languageLocale) as Record<
    ShLanguageLocale,
    ShLanguageLocale
  >,
  (languageLocale) => {
    return ShLocales.filter(
      (locale) => ShLocaleConfigs[locale].language === languageLocale,
    );
  },
);

function getLanguageTranslatableLocales(
  language: ShLanguageLocale,
): ShTranslatableLocale[] {
  return [language, ...ShLanguageToShLocales[language]];
}

function isLanguageHighlighted(
  language: ShLanguageLocale,
  highlightedLocales: ShTranslatableLocale[],
): boolean {
  return (
    intersection(getLanguageTranslatableLocales(language), highlightedLocales)
      .length > 0
  );
}

const LocaleRow = ({
  locale,
  translation,
  setTranslation,
  highlighted,
  englishTranslation,
  html,
}: {
  locale: ShTranslatableLocale;
  translation: string;
  setTranslation: (locale: ShTranslatableLocale, text: string) => void;
  highlighted: boolean;
  englishTranslation: string;
  html: boolean;
}) => {
  return (
    <div css={{ width: '100%' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 5,
          marginBottom: 5,
          alignItems: 'center',
          flex: 1,
        }}
      >
        <div css={{ width: 80, color: highlighted ? 'red' : undefined }}>
          <b>{locale}</b>
        </div>
        <div css={{ flex: 1 }}>
          <ShInputTextarea
            themeSize={'s'}
            value={translation ?? ''}
            onChange={(e) => setTranslation(locale, e.target.value)}
            placeholder="No translation yet"
            rows={Math.floor((translation ? translation.length : 0) / 60) + 1}
          />
          {html && (
            <div css={{ fontSize: 12, marginTop: 5 }}>
              <div>
                <b>Html version</b>
              </div>
              <div>
                <div dangerouslySetInnerHTML={{ __html: translation }} />
              </div>
            </div>
          )}
        </div>
      </div>
      {locale !== 'en' && (
        <ShTranslationAi
          message={englishTranslation}
          locales={[locale]}
          situation={'shoootinTranslation'}
          setValue={(value: string) => setTranslation(locale, value)}
        />
      )}
    </div>
  );
};

const LanguageLocaleSection = ({
  language,
  translations,
  setTranslation,
  highlightedLocales,
  html,
}: {
  language: ShLanguageLocale;
  translations: Record<ShTranslatableLocale, string>;
  setTranslation: (locale: ShTranslatableLocale, text: string) => void;
  highlightedLocales: ShTranslatableLocale[];
  html: boolean;
}) => {
  const locales = ShLanguageToShLocales[language];

  const canDisplayLocales =
    locales.length > 1 &&
    locales.filter(
      (locale) => !!translations[locale] && translations[locale] !== '',
    ).length > 0;

  const highlightedLanguage = isLanguageHighlighted(
    language,
    highlightedLocales,
  );

  const containsHighlightedLocale =
    intersection(highlightedLocales, locales).length > 0;

  const [displayAllLocale, setDisplayAllLocale] = useState<boolean>(
    canDisplayLocales && containsHighlightedLocale,
  );

  const englishTranslation = translations['en'];

  return (
    <div
      css={{
        border: `1px solid ${ShColors.blackLL}`,
        padding: 10,
        marginBottom: 10,
      }}
    >
      <div css={{ display: 'flex', justifyContent: 'space-between' }}>
        <LocaleRow
          locale={language}
          translation={translations[language]}
          setTranslation={setTranslation}
          highlighted={highlightedLanguage}
          englishTranslation={englishTranslation}
          html={html}
        />
        {locales.length > 1 && (
          <div
            css={{
              paddingLeft: 10,
            }}
            onClick={() => setDisplayAllLocale(!displayAllLocale)}
          >
            <ShLinkText bold={false}>
              {displayAllLocale ? 'hide all' : 'show all'} {language}
            </ShLinkText>
          </div>
        )}
      </div>
      {displayAllLocale && (
        <div>
          {locales.length > 1 &&
            locales.map((locale) => {
              return (
                <LocaleRow
                  key={locale}
                  locale={locale}
                  translation={translations[locale]}
                  setTranslation={setTranslation}
                  highlighted={highlightedLocales.includes(locale)}
                  englishTranslation={englishTranslation}
                  html={html}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export const ShTranslationModal = ({
  id,
  updateMessage,
  close,
  locale,
}: {
  id: string;
  updateMessage: (message: string) => void;
  close: () => void;
  locale: ShLocale;
}) => {
  return (
    <ShModal
      title={
        <div>
          Translate <small>{id}</small>
        </div>
      }
      size={'l'}
      onClose={close}
      content={
        <ShTranslationKeyFormAsync
          id={id}
          highlightedLocales={[locale]}
          onSaveSuccess={(translations: ShTranslationsDTO) => {
            const languageLocale: ShLanguageLocale =
              ShLocaleConfigs[locale].language;

            const translation =
              translations[locale] || translations[languageLocale];

            updateMessage(translation);
          }}
        />
      }
    />
  );
};

const ShTranslationKeyFormAsync = ({
  id,
  ...props
}: {
  id: string;
} & Omit<ShTranslationKeyFormProps, 'translationKey'>) => {
  const translationKeyAsync = useAsync(ShTranslationApi.getTranslationKey, [
    id,
  ]);
  return translationKeyAsync.result ? (
    <ShTranslationKeyForm
      translationKey={translationKeyAsync.result}
      {...props}
    />
  ) : (
    <div css={{ padding: 50 }}>
      <ShSpinner size={'s'} />
    </div>
  );
};

export type ShTranslationKeyFormProps = {
  translationKey: ShTranslationKeyDTO;
  highlightedLocales?: ShTranslatableLocale[];
  onSaveSuccess?: (translations: ShTranslationsDTO) => void;
};
export const ShTranslationKeyForm = ({
  translationKey,
  onSaveSuccess,
  highlightedLocales = [],
}: ShTranslationKeyFormProps) => {
  const [translations, setTranslations] = useState<ShTranslationsDTO>(
    translationKey.translations,
  );

  const setTranslation = (locale: ShTranslatableLocale, text: string) => {
    setTranslations((state) => ({ ...state, [locale]: text }));
  };

  const [html, setHtml] = useState(translationKey.html);

  const saveButton = (
    <ShButtonAsync
      size={'m'}
      onClick={async () => {
        await ShTranslationApi.postTranslations(
          translationKey.key,
          translations,
        );
        console.debug('translations saved!', translations);
        onSaveSuccess && (await onSaveSuccess(translations));
      }}
    >
      Save
    </ShButtonAsync>
  );

  const highlightedLanguages: ShLanguageLocale[] = ShLanguageLocales.filter(
    (language) => isLanguageHighlighted(language, highlightedLocales),
  );

  const highlightedFirstLanguages: ShLanguageLocale[] = sortBy(
    ShLanguageLocales,
    (language) => !highlightedLanguages.includes(language),
  );

  return (
    <ShThemeProvider theme={TranslationsPageTheme}>
      <div css={{ paddingTop: 10, paddingBottom: 10 }}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div css={{ paddingBottom: 10 }}>
            Translations for key : {translationKey.key}
          </div>
          <div css={{ display: 'flex', gap: 5 }}>
            <div>
              <ShButton
                size={'m'}
                onClick={() => setHtml(!html)}
                variant={'default'}
              >
                html
              </ShButton>
            </div>
            <div>{saveButton}</div>
          </div>
        </div>
        <div>
          {highlightedFirstLanguages.map((language) => {
            return (
              <LanguageLocaleSection
                key={language}
                translations={translations}
                language={language}
                setTranslation={setTranslation}
                highlightedLocales={highlightedLocales}
                html={html}
              />
            );
          })}
        </div>
      </div>
      <div css={{ paddingTop: 20, textAlign: 'right' }}>{saveButton}</div>
    </ShThemeProvider>
  );
};
